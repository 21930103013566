.login-left-panel {
  background: url(../../images/login_bg_img.png);
  width: 593px;
  height: 100vh;
  padding: 0px 50px;
  object-fit: cover;
  background-size: 100% 100%;
  .top-logo {
    padding: 55px 0px;
  }
  .heading-txt {
    span {
      display: block;
      font-weight: 300;
      font-size: 28px;
      line-height: 30px;
      color: $white;
    }
    h1 {
      font-weight: 600;
      font-size: 46px;
      line-height: 50px;
      color: $white;
    }
  }
}
.login-right-panel {
  background: $white;
  padding: 150px 100px;
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: $heading-color;
  }
  .login-tab {
    .nav-pills {
      display: flex;
      margin-top: 20px;
      .nav-item {
        margin-right: 40px;
        .nav-link {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: rgba($heading-color, 0.87);
          border-bottom: 2px solid transparent;
          padding-bottom: 6px;
          &.active {
            border-color: #0bbddd;
            font-weight: 600;
            color: #0bbddd;
          }
        }
      }
    }
    .tab-pane {
      padding-top: 32px;
      .material {
        .form-input {
          width: 360px;
          padding-left: 60px;
        }
        .otp-time {
          font-size: 11px;
          line-height: 16px;
          color: rgba($heading-color, 0.5);
          position: absolute;
          right: 15px;
          top: 21px;
        }
        .form-label {
          &:before {
            left: 60px;
          }
        }
        .form-input:focus ~ label:before {
          top: -8px;
        }
        .icn-login {
          position: absolute;
          left: 23px;
          top: 14px;
          color: $heading-color;
          font-size: 21px;
        }
      }
    }
  }
  input:-internal-autofill-selected {
    background-color: $white !important;
  }
}
.login-outer {
  background: $white;
  border-radius: 20px;
  display: flex;
  .error {
    input {
      border: 1px solid #ff2e2e;
    }
    .erroe-txt {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: #ff2e2e;
      display: block;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.otp-success-icon {
  background: #34c48b;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  color: $white;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 18px;
  right: 16px;
}

.btn-submit-resend {
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    width: calc(50% - 10px);
    min-width: auto;
  }
}
.role-outer {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .role-container {
    font-family: "Poppins", sans-serif;
    background: #fff;
    border-radius: 25px 0 0 25px;
    width: 630px;
    height: 100%;
    padding: 40px;
    position: absolute;
    right: 0;
    overflow-y: auto;
    border: 0;
    .close_icn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 28px;
      color: #000;
      cursor: pointer;
    }
  }
  .user-permission {
    .p-none {
      pointer-events: none;
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .role-feature-list {
    .add-role-list {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-permission {
        display: flex;
        align-items: center;
        .custom-control {
          margin-left: 10px;
        }
      }
      .custom-control-label::before {
        height: 16px;
        width: 16px;
      }
      .custom-control-label::after {
        top: 1px;
        left: 2px;
      }
      .custom-control-input {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.reload {
  position: relative;
  top: -10px;
  cursor: pointer;
  color: #000;
}
.password-show {
  font-size: 20px;
  position: absolute;
  right: 18px;
  top: 12px;
}
.error-captcha{
  margin-top: -10px!important;
  margin-bottom: 10px!important;
}

.btn-google {
  &.btn-line {
    min-width: auto;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    height: 60px;
    a{
      color: $heading-color;
      font-size: 16px;
    }
    svg{
      width: 40px !important;
      height: auto !important;
    }
    &:hover{
      background: transparent;
      border-color: $promotional-color;
      a{
        color: $heading-color;
      }
    }
  }
}

.gsign {
  width: 258px;
  margin-bottom: 10px;
  margin-left: 16px;
}